// @mui material components

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components

// Data
import {
  faChevronDown,
  faChevronUp,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Icon, Tooltip, Typography } from "@mui/material";
import { setGlobalState } from "Global";
import { OCRAPI, docstoreAPI, featureAPI } from "api";
import "css/forms/dropdown.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileThumbnailCard from "./fileThumbnailCard";
import UploadFileCard from "./uploadFileCard";
import LoadingOverlay from "react-loading-overlay";
import DialogWrapper from "utils/dialog/dialogWrapper";
import FileModal from "./showFile";
import FilesSkeleton from "skeletons/forms/filesSkeleton";
import CustomLoader from "utils/customLoader";
import { useTheme } from "@emotion/react";
import boxShadow from "assets/theme/functions/boxShadow";
import ReactPlayer from "react-player";

export default function CaseFiles() {
  const navigation = useNavigate();
  const params = useParams();

  const [userFiles, setUserFiles] = useState({});

  const [stepName, setStepName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});
  const theme = useTheme();

  const [searching, setSearching] = useState(false);
  const [suggestedFiles, setSuggestedFiles] = useState([]);

  const getFiles = () => {
    featureAPI
      .getFiles(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          let tempData = res.data;
          // Assign first value of the dictionary to the current features if features_isempty is not true else assign prev_features' first dict value
          let tempFiles = {};
          if (Object.keys(Object.values(tempData.files)[0]).length > 0) {
            tempFiles = tempData["files"];
          } else {
            tempFiles = tempData["prev_files"];
          }
          setInitialLoading(false);
          setStepName(Object.keys(tempFiles)[0]);
          tempFiles = Object.values(tempFiles)[0];
          setUserFiles(tempFiles);
        }
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  };

  const deleteFile = (fileName, fileFeatureName, stepName) => {
    setIsLoading(true);
    featureAPI
      .deleteFile({
        case_id: params.case_id,
        filename: fileName,
        feature_name: fileFeatureName,
        step_name: stepName,
      })
      .then((res) => {
        if (res.status == 200) {
          getFiles();
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const uploadFiles = (stepName, fileListName, fileList) => {
    const formdata = new FormData();
    setIsLoading(true);
    let files_feature_list = [];
    Array.from(fileList).forEach((file) => {
      files_feature_list.push(fileListName);

      formdata.append("files_list", file);
    });
    formdata.append("files_feature_list", files_feature_list);

    featureAPI
      .updateFiles(params.case_id, stepName, formdata)
      .then((res) => {
        if (res.status == 200) {
          getFiles();
          setIsLoading(false);
          setGlobalState("error", {
            open: true,
            message: "Files SuccessFully uploaded.",
            type: "success",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setInitialLoading(true);
    getFiles();
    getCaseDetails();
  }, []);

  const getCaseDetails = () => {
    setSearching(true);
    featureAPI
      .getFeatures(params.case_id)
      .then((res) => {
        if (res.status == 200) {
          let featuresData =
            res.data["prev_features"]["Enter on claims report"];
          getSuggestedFiles(featuresData);
        }
      })
      .catch((error) => {
        setSearching(false);
        console.log(error);
      });
  };

  const getSuggestedFiles = (caseFeatures) => {
    let searchQuery = ` ${caseFeatures["Customer"]?.value} ${caseFeatures["Document Number"]?.value} ${caseFeatures["Invoice Number"]?.value} ${caseFeatures["P&G Delivery Number"]?.value} ${caseFeatures["Claim Amount (ZAR)"]?.value?.toString()} `;
    docstoreAPI
      .searchFiles({ search_query: searchQuery, vector_search: true })
      .then((res) => {
        if (res.status == 200) {
          let tempData = [
            ...res.data.llm_response,
            ...res.data.initial_response,
          ];
          setSuggestedFiles(tempData);
          setSearching(false);
        }
      })
      .catch((error) => {
        setSearching(false);
        console.log(error);
      });
  };

  return (
    <MDBox>
      {suggestedFiles.length > 0 && (
        <MDBox
          sx={{
            fontSize: "15px",
            paddingX: "1rem",
            fontWeight: "500",
          }}
          color={theme.palette.text.secondary}
        >
          AI Suggested Files:
        </MDBox>
      )}
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingX: "1rem",
        }}
      >
        {searching && (
          <ReactPlayer
            url="/gif/Searching Document.mp4"
            loop={true}
            playing={true}
            height={"200px"}
            width={"300px"}
          />
        )}
        {suggestedFiles.length > 0 &&
          action &&
          suggestedFiles.map((specificFile, index) => {
            if (index < 5) {
              return (
                <FileThumbnailCard
                  key={specificFile["filename"]}
                  file={{
                    name: specificFile["filename"],
                    download_link: specificFile["s3_url"],
                  }}
                  showIcons={true}
                  fileFeatureName={specificFile["filename"]}
                  handleThumbnailClick={() => {
                    setShowModal(true);
                    setSelectedFile({
                      ...specificFile,
                      name: specificFile["filename"],
                      file_hash: specificFile["s3_url"],
                      fileName: specificFile["filename"],
                      fileFeatureName: action.file_feature_name,
                    });
                  }}
                />
              );
            }
          })}
      </MDBox>
      {initialLoading ? (
        <FilesSkeleton />
      ) : (
        <>
          <LoadingOverlay
            active={isLoading}
            spinner={<CustomLoader />}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#F6F6F6",
                opacity: "0.6",
              }),
            }}
            text="Loading your content..."
          >
            <MDBox sx={{ padding: "1rem" }}>
              {/* {isLoading==true && <FilesSkeleton />} */}
              {Object.entries(userFiles).map(([fileCategory, fileDetails]) => {
                return (
                  <MDBox
                    key={fileCategory}
                    sx={{
                      position: "relative",
                      marginTop: "1rem",
                      padding: "0.5rem",
                      borderRadius: "10px",
                      // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
                      border: `1px solid ${theme.palette.text.light}`,
                    }}
                  >
                    <MDBox
                      sx={{
                        fontSize: "0.8rem",
                        marginLeft: "1rem",
                        display: "flex",
                        // backgroundColor:theme.palette.primary.light,
                      }}
                    >
                      <MDBox
                        class="material-symbols-outlined"
                        style={{ color: theme.palette.primary.main }}
                      >
                        upload_file
                      </MDBox>
                      <MDBox
                        sx={{
                          marginLeft: "1rem",
                          fontSize: theme.typography.size["md"],
                        }}
                      >
                        {fileCategory}
                      </MDBox>
                    </MDBox>
                    <MDBox sx={{ display: "flex", position: "relative" }}>
                      {fileDetails.map((specificFile) => {
                        return (
                          <FileThumbnailCard
                            key={specificFile}
                            file={specificFile}
                            deleteFile={deleteFile}
                            stepName={stepName}
                            fileFeatureName={fileCategory}
                            handleThumbnailClick={() => {
                              setShowModal(true);
                              setSelectedFile({
                                ...specificFile,
                                fileName: specificFile["name"],
                                stepName: stepName,
                                fileFeatureName: fileCategory,
                              });
                            }}
                          />
                        );
                      })}
                      <UploadFileCard
                        fileName={fileCategory}
                        length={fileDetails.length}
                        uploadFiles={uploadFiles}
                        stepName={stepName}
                      />
                    </MDBox>
                  </MDBox>
                );
              })}
            </MDBox>
          </LoadingOverlay>

          {/* File Viewer */}
          {showModal && (
            <DialogWrapper
              handleClose={() => setShowModal(false)}
              width={"35%"}
            >
              <MDBox className={"fileViewer"}>
                <FileModal file={selectedFile} key={selectedFile.fileName} />
              </MDBox>
            </DialogWrapper>
          )}
        </>
      )}
    </MDBox>
  );
}
