import Icon from "@mui/material/Icon";
import Bulk from "layouts/bulkupload/index";
import AccessDenied from "layouts/authentication/access-denied";
// import Dashboard from "layouts/dashboard";
import History from "layouts/history/index";
import Logout from "layouts/logout";
import Tables from "layouts/tables";
import CreateCase from "layouts/tables/createCase";
import WorkflowUpdate from "layouts/workflowUpdate/workflowUpdate";
import UserManagement from "layouts/admin/userManagement";
import FormDetails from "layouts/formDetails";
import Analytics from "layouts/analytics/Analytics";
import Profile from "layouts/profile/Profile";
import WorkflowView from "layouts/workflowView/WorkflowView";
import MainChat from "layouts/chat";

export const defaultRoutes = [
  {
    type: "",
    name: "Profile",
    key: "profile",
    icon: <span class="material-symbols-outlined">account_box</span>,
    route: "/profile",
    path: "/profile",
    access: ["agent1", "admin", "agent2", "superuser", "3PA", "3PU"],
    component: <Profile />,
    showChatbot: true
  },  
  

  {
    type: "collapse",
    name: "Analytics",
    key: "analytics",
    icon: <span class="material-symbols-outlined">bar_chart_4_bars</span>,
    route: "/analytics",
    path: "/analytics", // Using Default case id for developement
    access: ["agent1", "admin", "superuser", "agent2"],
    component: <Analytics/> ,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "Claims",
    key: "cases",
    icon: <span class="material-symbols-outlined">folder_open</span>,
    path: "/:agent_id/cases",
    access: ["agent1", "admin", "agent2", "superuser", "3PA", "3PU"],
    component: <Tables />,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "User Management",
    key: "userManagement",
    icon: <span class="material-symbols-outlined">group</span>,
    route: "/admin/userManagement",
    path: "/admin/userManagement",
    access: ["superuser"],
    component: <UserManagement />,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "Bulk Case Upload",
    key: "bulk",
    icon: <span class="material-symbols-outlined">cloud_upload</span>,
    route: "/bulk",
    path: "/bulk",
    access: ["admin", "superuser"],
    component: <Bulk />,
    showChatbot: true
  },
  {
    type: "",
    name: "Case",
    key: "case",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        notifications
      </Icon>
    ),
    route: "/cases/case",
    path: "/cases/:case_id",
    access: ["agent1", "admin", "superuser", "agent2", "3PA", "3PU"],
    component: <FormDetails />,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "Worflow Update",
    key: "workflowUpdate",
    icon: <span class="material-symbols-outlined">rebase_edit</span>,
    route: "/workflowUpdate",
    path: "/workflowUpdate",
    access: ["superuser"],
    component: <WorkflowUpdate />,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "Worflow View",
    key: "workflowview",
    icon: <span class="material-symbols-outlined">account_tree</span>,
    route: "/workflowview",
    path: "/workflowview",
    access: ["agent2", "superuser", "agent1", "admin", "3PA", "3PU"],
    component: <WorkflowView />,
    showChatbot: true
  },
  {
    type: "collapse",
    name: "Chat Intel",
    key: "texttosql",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        smart_toy_rounded
      </Icon>
    ),
    route: "/texttosql",
    path: "/texttosql",
    access: ["agent2", "superuser", "agent1", "admin", "3PA", "3PU"],
    component: <MainChat />,
  },
  {
    type: "collapse",
    name: "AI Analytics",
    key: "AIAnalytics",
    icon: <span class="material-symbols-outlined">neurology</span>,
    route: "/workflowview",
    path: "/workflowview",
    href: process.env.REACT_APP_API_URL_MAIN + "/texttosql",
    componentType: "premium",
    access: ["agent2", "superuser", "agent1", "admin"],
    // component: <WorkflowView />,
  },
  {
    type: "",
    name: "Logout",
    key: "access-denied",
    icon: <span class="material-symbols-outlined">logout</span>,
    route: "/logout",
    path: "/logout",
    access: ["agent2", "superuser", "agent1", "admin", "3PA", "3PU"],

    component: <Logout />,
  },
  {
    type: "",
    name: "Access Denied",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        assignment
      </Icon>
    ),
    route: "/access-denied",
    path: "/access-denied",
    access: ["agent2", "superuser", "agent1", "admin", "3PA", "3PU"],

    component: <AccessDenied />,
  },
];
